import { render } from 'datocms-structured-text-to-plain-text';

const readTestimony: any = {
  fr: 'Lire le témoignage',
  en: 'Read case study',
  es: '',
};

export const getSectors = (datoClients: any, setSectors: any, sectors: any) => {
  datoClients.map(
    (client: any) =>
      client.hero[0]?.sector?.clientSector &&
      !sectors.includes(client.hero[0]?.sector.clientSector) &&
      sectors.push(client.hero[0]?.sector.clientSector),
  );
  setSectors(sectors);
};

export const getSizes = (datoClients: any, sizes: any, setSizes: any) => {
  datoClients.map(
    (client: any) =>
      client.hero[0]?.clientSizeLink?.clientSize &&
      !sizes.includes(client.hero[0]?.clientSizeLink?.clientSize) &&
      sizes.push(client.hero[0]?.clientSizeLink?.clientSize),
  );
  sizes.length && sizes.sort();
  setSizes(sizes);
};

export const getClients = (
  datoClients: any,
  clients: any,
  setClients: any,
  locale: any,
) => {
  const _datoClients = datoClients.filter((client: any) => client?.slug);
  for (const client of _datoClients) {
    const clientInfo = client.hero[0];
    const quote = client.blocks.find(
      (item: any) => item.__typename === 'DatoCmsQuote',
    );

    clients.push({
      image: clientInfo?.image,
      logo: clientInfo?.logo,
      subtitle: render(quote?.quote),
      title: clientInfo?.title,
      btnTitle: readTestimony[locale],
      capsText: quote?.information,
      internalUrl: {
        slug: client.slug,
        locale,
        trackingPageCategory: { title: 'client' },
      },
      sector: clientInfo?.sector?.clientSector,
      size: clientInfo?.clientSizeLink?.clientSize,
    });
  }

  setClients(clients);
};

export const getUtms = (setSelectedSectors: any, setSelectedSizes: any) => {
  const utms = window.location.href.split('?');
  utms.length &&
    utms[utms.length - 1].split('&').forEach((utm) => {
      const formattedUtm = utm.split('=');
      const parameterName = formattedUtm[0];
      const cleanValue = formattedUtm[1]?.endsWith('/')
        ? formattedUtm[1].slice(0, -1)
        : formattedUtm[1];
      const parameterValue = decodeURI(cleanValue);
      if (parameterName === 'sector') {
        setSelectedSectors(parameterValue, true);
      }
      if (parameterName === 'size') {
        setSelectedSizes(parameterValue, true);
      }
    });
};
