import { CardsList } from 'libs/brand-system/src/templates/CardsList';
import { Filter } from 'libs/brand-system/src/components/Filter';
import { useFilter } from 'libs/brand-system/src/utils/useFilter';
import React, { FC, useEffect, useState } from 'react';

import { CardWithoutBg } from 'libs/growth-platform-brand-system-v2/src/layouts/CardWithoutBg';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { morphism } from 'morphism';
import { schemaCardWithoutBg } from '../../../morphismSchema/layouts/schemaCardWithoutBg';
import { schemaCardButtonLink } from '../../../morphismSchema/schemaCardButtonLinkProps';
import { getClients, getSectors, getSizes, getUtms } from './logic';

export interface IClientsFilter {
  datoClients?: any[];
  sectorFilter?: boolean;
  sectorFilterLabel?: string;
  sizeFilter?: boolean;
  sizeFilterLabel?: string;
  seeMoreButtonLabel?: string;
  locale?: string;
  className?: string;
  noResultText?: string;
}

const ClientsFilter: FC<IClientsFilter> = ({
  datoClients,
  sectorFilter,
  sectorFilterLabel,
  sizeFilter,
  sizeFilterLabel,
  seeMoreButtonLabel,
  noResultText,
  locale,
  className,
}: any) => {
  const [sectors, setSectors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [clients, setClients] = useState([]);
  const [sectorsSelected, setSelectedSectors] = useFilter();
  const [sizesSelected, setSelectedSized] = useFilter();
  const [filteredClients, setFilteredClients] = useState([]);
  const [clientsToRender, setClientsToRender] = useState([]);
  const screenSize = useScreenSize();
  const isClientLink = true;

  useEffect(() => {
    getSectors(datoClients, setSectors, sectors);
    getSizes(datoClients, sizes, setSizes);
    getClients(datoClients, clients, setClients, locale);
    getUtms(setSelectedSectors, setSelectedSized);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filtered: any = [];
    clients.forEach((client: any) => {
      let sectorFit = false;
      let sizeFit = false;

      sectorsSelected.map(
        (sector: any) => client.sector === sector && (sectorFit = true),
      );
      sizesSelected.map((size) => client.size === size && (sizeFit = true));

      if (
        (sectorsSelected.length &&
          sizesSelected.length &&
          sectorFit &&
          sizeFit) ||
        (sectorsSelected.length && !sizesSelected.length && sectorFit) ||
        (!sectorsSelected.length && sizesSelected.length && sizeFit)
      ) {
        filtered.push(client);
      }
    });

    setFilteredClients(() => filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorsSelected, sizesSelected]);

  useEffect(() => {
    if (filteredClients.length) {
      setClientsToRender(filteredClients);
    } else {
      if (!sectorsSelected.length && !sizesSelected.length) {
        setClientsToRender(clients);
      } else {
        setClientsToRender([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredClients, sectorsSelected, sizesSelected]);

  const clientsCards = clientsToRender.map((client: any, key: number) => {
    const { button, linkAttributes, ...linkProps } = morphism(
      schemaCardButtonLink,
      {
        ctaButton: client,
        isClientLink: isClientLink,
        screenSize,
        locale,
      },
    );

    const cta = morphism(schemaCardWithoutBg, {
      ...client,
      ...linkProps,
      ctaButton: client?.ctaButton,
    });
    return (
      <ButtonTracking
        component={CardWithoutBg}
        {...{
          ...cta,
          ...linkAttributes,
          className: 'px-12 py-32 w-full lg:py-36',
        }}
        index={key}
        labelTracking={cta.title}
        section={'clientsCards'}
        key={key}
      />
    );
  });

  return (
    <section
      className={`ClientsFilter bg-blue-21 ${className} pb-64 lg:pb-104`}
    >
      {(sectorFilter || sizeFilter) && (
        <div className="container">
          <div className="relative flex gap-16 mb-64">
            {sectorFilter && (
              <div className="z-30">
                <Filter
                  buttonTitle={sectorFilterLabel as any}
                  availableFilters={sectors}
                  selectedFilters={sectorsSelected}
                  locale={locale as any}
                  onFilterClick={setSelectedSectors}
                />
              </div>
            )}
            {sizeFilter && (
              <div className="z-10">
                <Filter
                  buttonTitle={sizeFilterLabel as any}
                  availableFilters={sizes}
                  selectedFilters={sizesSelected}
                  locale={locale as any}
                  onFilterClick={setSelectedSized}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {clientsToRender.length ? (
        <CardsList
          cards={clientsCards}
          backgroundColor="blue"
          buttonType="secondary"
          shouldDisplay={6}
          addSeeMoreButton
          seeMoreBatchSize={screenSize === 'md' ? 2 : 3}
          seeMoreButtonLabel={seeMoreButtonLabel}
        />
      ) : (
        <p className="container ">{noResultText}</p>
      )}
    </section>
  );
};

export default ClientsFilter;
